{
  "common": {
    "save": "Salva",
    "delete": "Cancella",
    "cancel": "Annulla",
    "back": "Indietro",
    "actions": "Azioni",
    "send": "Invia",
    "edit": "Modifica",
    "search_placeholder": "Cerca",
    "loading": "Caricamento in corso...",
    "no_records_found": "Nessun risultato disponibile",
    "refresh": "Aggiorna",
    "reset": "Reset",
    "date_range": "Dal: {fromDate} Al: {toDate}",
    "advanced_filters": "Filtri avanzati",
    "hours": "ora | ore",
    "minutes": "minuto | minuti",
    "seconds": "secondo | secondi",
    "minute": "minuto | minuti",
    "km": "Km",
    "days": "Giorno|Giorni",
    "required_fields": "I campi contrassegnati con * sono obbligatori"
  },
  "role": {
    "1": "Super admin",
    "10": "Operatore TMT",
    "20": "Cliente - Admin",
    "30": "Cliente - Operatore",
    "40": "Taker",
    "50": "Fornitore - Admin",
    "60": "Fornitore - Operatore "
  },
  "takers": {
    "new": {
      "title": "Nuovo Taker"
    },
    "detail": {
      "title": "Dettaglio Taker"
    },
    "list": {
      "title": "Elenco takers"
    }
  },
  "save": "Salva",
  "delete": "Cancella",
  "back": "Indietro",
  "message.success_message": "Operazione completata",
  "message.confirm_delete": "Conferma cancellazione ",
  "payment_type.anticipated": "Pagamento anticipato",
  "payment_type.postponed": "Pagamento posticipato",
  "cancel": "Annulla",
  "edit": "Modifica",
  "remove": "Rimuovi",
  "validation.is_required": "è obbligatorio | sono obbligatori",
  "validation.required": "Campo obbligatorio",
  "validation.email": "Formato email non corretto",
  "validation.confirm_pwd": "Le due password non coincidono.",
  "validation.complexity_pwd": "La password deve contenere almeno 1 maiuscola, 1 minuscola, 1 numero ed un carettere speciale (!#{'@'}$%^&*?_.,([)])",
  "validation.min_length": "Password troppo corta",
  "validation.form_generic": "Dati inseriti non validi o incompleti",
  "validation.form_generic_text": "I campi (*) sono necessari",
  "validation.required_this_or_fiscal_code": "Codice fiscale o P.Iva obbligatorio",
  "validation.required_this_or_vat": "Codice fiscale o P.Iva obbligatorio",
  "zones.new.title": "Nuova zona",
  "draw": "Disegna",
  "show_hide": "Visualizza / Nascondi ",
  "zones.field.price_km_flat": "Prezzo al Km",
  "zones.field.cost_km_flat": "Costi al Km",
  "zones.delete_message": "Procedere con la cancellazione della zona: {zoneName}",
  "restore": "Ripristina",
  "to_empty": "Svuota",
  "reset": "Azzera",
  "add": "Aggiungi",
  "orders.list.title": "Lista ordini",
  "orders.new.title": "Nuovo ordine",
  "orders.delete_confirm": "Confermi l'eliminazione degli ordini selezionati?",
  "new": "Nuovo",
  "new_order.pickup_note": "Note punto di ritiro",
  "registration.succesfull": "Registrazione completata ",
  "registration.welcome": "Benvenuto/a",
  "business_profile.title": "Profilo aziendale",
  "options": "Opzioni",
  "disable": "Disattiva",
  "enable": "Attiva",
  "go_to_detail": "Vedi dettagli",
  "takers_availability.list.title": "Disponibilità takers",
  "previous_week": "Indietro di una settimana",
  "next_week": "Avanti di una settimana ",
  "period": "Periodo",
  "availability.taker_not_available": "Non disponibile",
  "availability.taker_confirmed": "DIsponibilità confermata",
  "availability.taker_not_confirmed": "Disponibilità non confermata",
  "availabilities.instructions": "Clicca il pulsante cerca dopo aver selezionato la città e il periodo",
  "availabilities.no_records_found": "Non è stato trovato alcun risultato per la città e il periodo indicato",
  "time_slots.title": "Fasce orarie",
  "taker_availabilities.no_zone_message": "Non è possibile indicare le Disponibilità senza prima assegnare il Taker ad una specifica Zona. ",
  "active": "Attivato",
  "inactive": "Disattivato",
  "actions": "Azioni",
  "planning.title": "Assegnazioni interne",
  "planning_taker.title": "Pianificazione tragitto",
  "customer": {
    "singular": "Cliente",
    "plural": "Clienti",
    "detail": {
      "new": "Nuovo @:customer.singular",
      "edit": "Dettaglio @:customer.singular"
    },
    "list": {
      "title": "Elenco @:customer.plural"
    },
    "tabs": {
      "data": "Dati",
      "user_list": "Utenti",
      "zones": "Città personalizzate",
      "pickers_dropoff": "Punti di ritiro / consegna ",
      "rubric": "Punti di ritiro / consegna"
    },
    "user_dialog": {
      "edit": "Modifica utente",
      "new": "Nuovo utente"
    },
    "remove_zone": "Rmuovi città ",
    "pickers_title": "Lista punti di ritiro / consegna"
  },
  "entity": {
    "name": "Nome",
    "surname": "Cognome",
    "business_name": "Nome Azienda",
    "business_address": "Indirizzo",
    "business_city": "Città",
    "business_state": "Stato",
    "business_province": "Provincia",
    "business_postal_code": "Codice postale",
    "sdi": "SDI",
    "vat": "Partita IVA",
    "business_tel": "Telefono",
    "pec": "PEC",
    "email": "E-mail",
    "payment_type": "Tipo di pagamento",
    "user_data": "Dati referente",
    "password": "Password",
    "geo_data": "Dati geografici",
    "business_data": "Dati Azienda",
    "contact_details": "Recapiti",
    "other": "Altro",
    "business_note": "Note pagamento ",
    "note": "Note",
    "payment_section": "Pagamento",
    "costs_section": "Costi",
    "user_dialog": {
      "new": "Nuovo utente",
      "edit": "Modifica utente"
    }
  },
  "login": {
    "title": "Accedi",
    "subtitle": "Inserisci le tue credenziali per accedere",
    "username_label": "Username",
    "username_placeholder": "username",
    "password_label": "Password",
    "password_placeholder": "password",
    "error_message": "Credenziali errate. Controllare e riprovare.",
    "forget_pwd": "Password dimenticata ? ",
    "sign_up": "Registrati"
  },
  "menu": {
    "users": "Utenti",
    "vehicles": "Tipologie",
    "customers": "Clienti",
    "suppliers": "Fornitori",
    "takers": "Takers",
    "zones": "Elenco Città",
    "time_slots": "Fasce orarie",
    "availabilities": "Disponibilità",
    "orders": "Ordini",
    "rubric": "Rubrica",
    "planning": "Assegnazioni Interne",
    "planning_track": "Tracciato",
    "import_orders": "Import Ordini",
    "new_order": "Nuovo ordine",
    "billing_suppliers": "Fatture Fornitori/Taker",
    "billing_customers": "Fatture Clienti"
  },
  "order": {
    "status_0": "Creato",
    "status_10": "Accettato",
    "status_20": "Assegnato",
    "status_30": "Attesa ritiro",
    "status_40": "Ritirato",
    "status_50": "Attesa consegna",
    "status_200": "Consegnato",
    "status_300": "Annullato",
    "status_500": "Rifiutato",
    "payment_type_10": "Anticipato",
    "payment_type_20": "Posticipato",
    "new": {
      "zone": {
        "title": "Città"
      }
    },
    "preview_directions": "Anteprima percorso"
  },
  "taker": {
    "singular": "Taker",
    "plural": "Takers",
    "planning.title": "Pianificazione settimanale",
    "add_availability": "Aggiungi disponibilità",
    "av": {
      "waiting": "In attesa di conferma",
      "confirmed": "Confermata"
    },
    "tabs": {
      "data": "Dati",
      "zone": "Città",
      "availabilities": "Disponibilità"
    }
  },
  "supplier": {
    "tabs": {
      "data": "Dati",
      "user_list": "Lista utenti",
      "zones": "Città abilitate"
    }
  },
  "user": {
    "singular": "Utente",
    "plural": "Utenti",
    "detail": {
      "new": "Nuovo @:user.singular",
      "edit": "Modifica @:user.singular"
    },
    "name": "Nome",
    "surname": "Cognome",
    "email": "E-mail",
    "role": "Ruolo",
    "password.title": "Password",
    "zone.title": "Zone",
    "password": "Password",
    "confirm_password": "Conferma password",
    "zone.source": "Città disponibili",
    "zone.target": "Città selezionate",
    "active": "Utente attivato",
    "inactive": "Utente non attivato",
    "delete_message": "Procedere con la cancellazione dell'utente: {name} ?",
    "list": {
      "title": "Elenco utenti"
    }
  },
  "vehicle": {
    "singular": "Tipologia",
    "plural": "Tipologie",
    "icon": "Icona",
    "name": "Nome",
    "note": "Note",
    "loading_platform": "Piano di carico (cm)",
    "max_weight": "Peso max (Kg)",
    "detail": {
      "new": "Nuovo @:vehicle.singular",
      "edit": "Modifica @:vehicle.singular"
    },
    "list": {
      "title": "Elenco @:vehicle.singular"
    }
  },
  "zone": {
    "singular": "Città",
    "plural": "Città",
    "active": "Città attiva",
    "disabled": "Città disattivata",
    "name": "Nome @:zone.singular",
    "status": "Stato",
    "detail": {
      "new": "Nuova @:zone.singular",
      "edit": "Dettaglio @:zone.singular"
    },
    "list": {
      "title": "Elenco @:zone.plural"
    },
    "table": {
      "name": "@:zone.name",
      "status": "@:zone.status"
    }
  },
  "entity.fiscal_code": "Codice fiscale",
  "assign": "Assegna",
  "view": "Dettaglio",
  "orders.import.title": "Import Ordini da file",
  "lost_pwd.title": "Password dimenticata",
  "lost_pwd.subtitle": "Seguire la procedura per il reset della password",
  "clone": "Duplica",
  "table": {
    "search_placeholder": "Cerca",
    "messages": {
      "no_records_found": "Nessun risultato disponibile"
    }
  },
  "suppliers": {
    "list": {
      "title": "Elenco fornitori"
    },
    "new": {
      "title": "Nuovo fornitore"
    },
    "detail": {
      "title": "Dettaglio fornitore"
    }
  },
  "new_pwd": {
    "title": "Nuova password",
    "subtitle": "Scegli la tua nuova password. \n Minimo 8 caratteri, almeno un carattere maiuscolo, un simbolo ed un numero. ",
    "title_accomplish": "Nuova password impostata",
    "subtitle_accomplish": "Ora puoi tornare alla pagina di login e accedere all'applicazione con la tua nuova password."
  },
  "zone_autocomplete": {
    "placeholder": "Cerca città per nome"
  },
  "delivered": "Consegnato",
  "print": "Stampa",
  "billing_customers": {
    "title": "Lista movimenti",
    "new": "Nuovo report",
    "params": {
      "title": "Parametri"
    },
    "find_title": "Ricerca clienti",
    "type_10": "Credito",
    "type_20": "Debito",
    "type_15": "Rimborso",
    "empty_message": "Nessun report trovato"
  },
  "billing_suppliers": {
    "title": "Lista movimenti",
    "find_title": "Ricerca fornitori/takers"
  },
  "billing_customer": {
    "title": "Fattura cliente: {name}"
  },
  "billing_supplier": {
    "title": "Fattura per: {name}",
    "total_shifts": "Totale fasce",
    "total_orders": "Totale Ordini",
    "total": "Totale:",
    "note_placeholder": "Scrivi qui le note...",
    "note_label": "Note",
    "process": "Elabora",
    "table": {
      "empty_message": "Nessun ordine trovato",
      "title": "Riepilogo ordini"
    }
  },
  "orders": {
    "assigned_to_user": {
      "success": "Ordine assegnato con successo | Ordini assegnati con successo"
    }
  },
  "admin_kpi": {
    "km_total": "Km Totali",
    "km_avg": "Media Km",
    "price_avg": "Prezzo medio stimato / fatturato",
    "cost_avg": "Costo medio stimato / fatturato",
    "suppliers_takers_on_duty": "Fornitori/Taker in servizio",
    "pickup_zips_title": "Ritiri (10+ ordini)",
    "dropoff_zips_title": "Consegne (5+ ordini)",
    "supplier_type": "Fornitore",
    "taker_type": "Taker",
    "customer_more_orders_title": "Clienti con più ordini",
    "vehicles_title": "Tipologie",
    "priority_orders_title": "Ordini con priorità",
    "eta_driving_avg": "Tempo stimato medio",
    "real_driving_avg": "Tempo reale medio",
    "pickup_waiting_avg": "Tempo medio attesa ritiro ",
    "dropoff_waiting_avg": "Tempo medio attesa consegna ",
    "zones_title": "Numero consegne nelle diverse aree della zona",
    "date_range": "Periodo",
    "refresh": "Ricarica"
  },
  "messages": {
    "title": "Elenco messaggi",
    "send": "Invia messaggio",
    "placeholder": "Messaggio",
    "chat_with": "Chat con {name}",
    "threads_header": "Threads",
    "add_thread": "Aggiungi thread"
  },
  "topbar": {
    "messagges": "Messaggi"
  },
  "chat": {
    "title": "Chat di supporto"
  }
}
